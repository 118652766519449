<template>
  <div class="m-10">En cours...</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.$vs.loading();
    this.exchangeToken();
  },
  methods: {
    exchangeToken() {
      console.log("data", this.$route.params);
      this.$store.dispatch("auth/EXCHANGE_TOKEN", {
        token: this.$route.params.token,
        onData: ({ ok, user, data, message, errCode, isFirstLogin }) => {
          console.log(ok, user, data, message, errCode, isFirstLogin);
          this.$vs.loading.close();
          if (!ok) {
            return this.$vs.notify({
              time: 2500,
              text: message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
          switch (user.role) {
            case "DOCTOR":
              if (isFirstLogin === true) {
                return this.$router.push({ name: "settings" });
              }
              window.location = "/agenda";
              return; //this.$router.push({ name: "agenda" });
            case "TEAM-MEMBER":
              if (user.roles[0] == "Calendrier") {
                return this.$router.push("/agenda");
              } else if (user.roles[0] == "Patientèle") {
                return this.$router.push("/patients");
              } else if (user.roles[0] == "Consultations") {
                return this.$router.push("/consultations");
              } else {
                return this.$router.push("/Settings");
              }
            case "PHARMACY" || "PHARMACY-MEMBER":
              window.location = "/pharmacy/prescription";
              break;
            case "LABORATORY" || "LABORATORY-MEMBER":
              window.location = "/laboratory/prescription";
              break;
            default:
              return this.$router.push({ name: "doctorsList" });
          }
          return this.$router.push({ name: "doctorsList" });
        },
      });
    },
  },
};
</script>

<style lang="scss">
button,
select {
}

input {
  font-size: 17px;
  &::placeholder {
    font-size: 17px;
    padding: 0px 2px;
  }
}

label {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
</style>
